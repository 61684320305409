<template>
  <div class="container">
    <div class="accounts-top mb-5">
      <div class="account">
        <div class="account-name">
          <div class="account-switch-box">
            <button
              class="account-switch"
              :class="{ open: accountSwitch }"
              v-on:click="accountSwitch = !accountSwitch"
            >
              <BaseIcon name="arrow" />
            </button>
            <div class="account-switch-panel" :class="{ show: accountSwitch }">
              <button
                v-on:click="select(0, { name: 'IntoMedia Group', id: null })"
                class="account-item"
              >
                <BaseIcon name="office" />
                <div>
                  <div class="account-item-name">IntoMedia Group</div>
                  <div class="account-item-type">Összes elem</div>
                </div>
              </button>
              <button
                v-on:click="select(1, group)"
                class="account-item"
                v-for="group of groups"
                :key="group.id"
              >
                <BaseIcon :name="group.private ? 'lock' : 'stack'" />
                <div>
                  <div class="account-item-name">{{ group.name }}</div>
                  <div class="account-item-type">
                    {{ group.private ? "Saját csoport" : "Csoport" }}
                  </div>
                </div>
              </button>
            </div>
          </div>
          <h2 v-on:click="accountSwitch = !accountSwitch">
            {{ selected.name }}
          </h2>
        </div>
      </div>
    </div>
    <div class="timeline-container dragscroll">
      <div class="timeline">
        <div class="timeline-head">
          <div class="timeline-title"></div>
          <div
            class="timeline-month"
            :class="[yearChange(i) ? 'year' : '']"
            v-for="(date, i) in dates"
            :key="date.i"
          >
            {{ date.year }} {{ date.monthName }}
          </div>
        </div>
        <div
          class="timeline-item"
          v-for="service in services"
          :key="service.id"
        >
          <div class="timeline-title">
            <div class="timeline-name">{{ service.name }}</div>
            <div
              class="timeline-type"
              :style="'background: #' + service.type_color"
            >
              {{ service.type }}
            </div>
          </div>
          <div
            class="timeline-line"
            v-for="(line, i) in service.times"
            :key="line.id"
            :style="
              'margin-left:' +
              start(service, i, line.start_time) +
              'px;width: ' +
              width(line.start_time, line.end_time) +
              'px'
            "
          >
            <div
              class="timeline-start-date"
              v-if="+width(line.start_time, line.end_time) > 400"
            >
              {{ line.name }}
            </div>
            <div class="timeline-end-date">
              {{ date(line.end_time) }}
            </div>
            <div
              class="timeline-color"
              :style="'background: #' + service.color"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/hu";
import BaseIcon from "../../components/BaseIcon";

export default {
  name: "Services",
  components: { BaseIcon },
  created() {
    this.fetchData();
  },
  mounted() {
    const slider = document.querySelector(".dragscroll");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    now() {
      return Math.floor(Date.now() / 1000);
    },
    dates() {
      let dates = [];
      const d = new Date();
      let month = d.getMonth();
      let year = d.getFullYear();
      for (let i = 0; i < 24; i++) {
        dates.push({
          year: year,
          month: month,
          monthName: this.months[month],
          i: i,
        });
        month++;
        if (month > 11) {
          month = 0;
          year++;
        }
      }

      return dates;
    },
  },
  data() {
    return {
      accountSwitch: false,
      selected: {
        name: "IntoMedia Group",
        id: 0,
      },
      groups: [],
      months: [
        "január",
        "február",
        "március",
        "április",
        "május",
        "június",
        "július",
        "augusztus",
        "szeptember",
        "október",
        "november",
        "december",
      ],
      services: [],
    };
  },
  methods: {
    fetchData() {
      fetch(process.env.VUE_APP_API + "/services")
        .then((res) => res.json())
        .then((response) => {
          if (response.loginNeed) {
            this.$router.push("/login");
          }
          this.services = response;
        })
        .catch((error) => {
          this.message = error;
        });
    },
    date(val) {
      dayjs.locale("hu");
      dayjs.extend(localizedFormat);
      dayjs.extend(relativeTime);
      return dayjs().to(dayjs.unix(val));
    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    dayDiff(d1, d2) {
      let days;
      days = d2.getDate() - d1.getDate();
      return days <= 0 ? 0 : days;
    },
    width(start, end) {
      let d1 = new Date(this.now > start ? this.now * 1000 : start * 1000);
      let d2 = new Date(end * 1000);
      let e = this.monthDiff(d1, d2) * 240;
      e += this.dayDiff(d1, d2) * (240 / 31);
      return e;
    },
    start(service, i, start) {
      let d1 = new Date(
        i === 0 ? this.now * 1000 : service.times[i - 1].end_time * 1000
      );
      let d2 = new Date(start * 1000);
      if (d1 > d2) {
        return 1;
      }
      console.log(this.monthDiff(d1, d2));
      let e = this.monthDiff(d1, d2) * 240;
      e += this.dayDiff(d1, d2) * (240 / 31);
      return e;
    },
    yearChange(i) {
      if (i === 0) {
        return false;
      }
      return this.dates[i - 1].year !== this.dates[i].year;
    },
  },
};
</script>
